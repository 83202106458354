<template>
  <div class="d-flex justify-center align-center">
    <div v-if="$auth.loading || $auth.isAuthenticated">
      <v-progress-circular indeterminate color="primary" ></v-progress-circular>
    </div>
    <div class="text-center mt-15" v-else>
      <v-card elevation="2" outlined class="pa-5">
        <v-img src="@/assets/pillsbury-canvassed.jpg" class="mb-5 mt-15" max-height="200" contain />
        <div class="body-1 mb-5">
          Welcome to the Pillsbury Winthrop Shaw Pittman Application User Portal
        </div>
        <v-btn x-large color="primary" @click="Login">Log in</v-btn>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getInstance } from "@/auth/index";
import httpService from '@/plugins/httpService';

export default {
  data: () => ({
    loading: true
  }),
  
  methods: {
    ...mapActions({
      setAuthenticated: "app/setAuthenticated",
      setJwt: "app/setJwt",
      setUser: "app/setUser"
    }),
    
    initAuth0(fn) {
        // have to do this nonsense to make sure auth0Client is ready
        var instance = getInstance();
        if (instance.loading === false){ fn(); }

        instance.$watch("loading", loading => {
          if (loading === false) { fn(); }
        });
    },

    Login() {
      this.$auth.loginWithRedirect();
    },

    async redirectLogin(){
      if (this.$auth.isAuthenticated){

        console.log('logging in...');

        // push jwt to state
        const token = await this.$auth.getTokenSilently();
        
        this.setAuthenticated(true);
        this.setJwt(token);

        const response = await httpService.getUser();
        this.setUser({ name: response.data.data.user });

        this.$router.push({ name: 'dashboard' });
      } 
    }

  },
  
  created() {
    this.initAuth0(this.redirectLogin);
  }

};
</script>