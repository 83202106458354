<template>
  <v-app>
    <component :is="layout">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
      
      <div class="d-flex justify-center align-center" v-if="loading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </component>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
const default_layout = "default";

export default {
  name: 'App',

  computed: {
    ...mapGetters({
      loading: "app/loadingData"
    }),

    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    }
  },

  data: () => ({
    //
  }),
};
</script>
