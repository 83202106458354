<template>
  <div class="pa-10">
    <h1 class="mb-10">Dashboard</h1>
    <v-card class="mx-auto" max-width="500" v-if="data.length > 0">
      <v-list elevation="5">
        <v-subheader color="primary">APPLICATIONS</v-subheader>
        <v-divider class="pb-2"></v-divider>
        <v-list-item-group>
          <v-list-item v-for="d in data" :key="d.ident" :href="appUrl(d)" target="_blank" class="pt-1 pb-1">
            <v-list-item-icon>
              <v-icon color="primary">{{d.applicationicon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{d.applicationname}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    
    <v-card class="mx-auto text-center pa-5" max-width="500" v-if="data.length == 0 && !loading">
      <h3>You do not have any applications setup in the system.</h3>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import httpService from '@/plugins/httpService';

export default {
  name: 'Dashboard',
  data: () => ({
    data: []
  }),
  
  computed: {    
    ...mapGetters({
      loading: "app/loadingData",
      jwt: "app/jwt"
    })
  },

  methods: {

    ...mapActions({
      setLoadingData: "app/setLoadingData"
    }),

    appUrl(app){
      return app.applicationurl + '?code=' + this.jwt;
    },

    async getUserApplications() {
      const response = await httpService.getUserApplications();
      this.data = response.data.data;
    },
    
    async initData() {
      this.setLoadingData(true);
      await this.getUserApplications();
      this.setLoadingData(false);
    }

  },

  created() {
    this.initData();
  }
};
</script>
