import * as axios from "axios";
import store from '@/store';

const httpRequest = axios.create({ headers: {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
  } 
});

httpRequest.interceptors.request.use(function (config) {
  const token = store.getters['app/jwt'];
  if (token){ config.headers = { 'X-Auth-Token': `${token}`}; }
  return config;
});

httpRequest.interceptors.response.use(
  res => res,
  err => {
    if (err && err.response && err.response.status === 401) {
      store.commit('SET_AUTHENTICATED', false);
      store.commit('SET_JWT', null);
      store.commit('SET_USER', {});
      window.location.replace(process.env.VUE_APP_HOME_URL);
    }
  }
);

const getUser = async function(){
  
  let postBody = {
    commandType: ''
  };

  const response = await httpRequest.post(process.env.VUE_APP_API_URL, postBody);
  return response;
}
  
const getUserApplications = async function(){

  let postBody = {
    commandType: 'userApplications'
  };

  const response = await httpRequest.post(process.env.VUE_APP_API_URL, postBody);
  return response;
}
  
export default {
  getUser,
  getUserApplications
};