const state = {
    authentication: false,
    jwt: null,
    loadingData: false, 
    user: {}
  };
  
  const getters = {
    authenticated: (state) => state.authenticated,
    jwt: (state) => state.jwt,
    loadingData: (state) => state.loadingData,
    user: (state) => state.user
  };
  
  const mutations = {
    
    SET_AUTHENTICATED(state, authenticated) {
      state.authenticated = authenticated;
    },
    SET_JWT(state, jwt) {
      state.jwt = jwt;
    },
    SET_LOADING_DATA(state, loading) {
      state.loadingData = loading;
    },
    SET_USER(state, user) {
      state.user = user;
    }
  };
  
  const actions = {  
    setAuthenticated({ commit }, authenticated) {
      commit("SET_AUTHENTICATED", authenticated);
    },
    setJwt({ commit }, jwt) {
      commit("SET_JWT", jwt);
    },
    setLoadingData({ commit }, loading) {
      commit("SET_LOADING_DATA", loading);
    },
    setUser({ commit }, user) {
      commit("SET_USER", user);
    }
  };
  
  const module = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  };
  
  export default module;
  