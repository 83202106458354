import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "@/auth/authGuard";

import AccessDenied from '@/views/AccessDenied.vue';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/Login.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Login
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authGuard,
      component: Dashboard
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
