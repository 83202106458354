<template>
  <div class="pa-10">
    <v-card max-width="500" class="mx-auto text-center pa-1 mt-15">
      <h3 class="pa-5">You do not have access to this application.</h3>      
      <div class="pa-5">
        <v-btn :to="{name: 'dashboard'}" color="primary">
          Click Here to Return to the Dashboard
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'AccessDenied',
  data: () => ({}),
  
  methods: {}
};
</script>
